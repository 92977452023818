export default [
    {
        path: '/quickOffer',
        name: 'quickOffer',
        component: resolve => require(['/src/views/quick/offer.vue'], resolve),
        meta: { title: '快速下单', isToken: true, isNeedHeader: true }
    },
    {
        path: '/orderDetail',
        name: 'orderDetail',
        component: resolve => require(['/src/views/payManage/orderList/detail.vue'], resolve),
        meta: { title: '订单详情', isToken: true }
    },
    {
        path: '/orderPay',
        name: 'orderPay',
        component: resolve => require(['/src/views/payManage/orderPay/index.vue'], resolve),
        meta: { title: '订单支付', isToken: true }
    },
    {
        path: '/paySuc',
        name: 'paySuc',
        component: resolve => require(['/src/views/payManage/orderPay/paySuc.vue'], resolve),
        meta: { title: '支付成功', isToken: true }
    },
    {
        path: '/orderSubmit',
        name: 'orderSubmit',
        component: resolve => require(['/src/views/payManage/orderSubmit/index.vue'], resolve),
        meta: { title: '订单提交', isToken: true, isNeedHeader: true }
    },
    {
        path: '/shopCart',
        name: 'shopCart',
        component: resolve => require(['/src/views/shopCart/index'], resolve),
        meta: { title: '购物车', isToken: true, isNeedHeader: true }
    },
    {
        path: '/evaluate',
        name: 'evaluate',
        component: resolve => require(['/src/views/payManage/orderList/evaluate'], resolve),
        meta: { title: '订单评价', isToken: true, isNeedHeader: true }
    },
]
