<template>
  <div id="app">
    <router-view ></router-view>
  </div>
</template>
<script>


export default {
  name: 'APP',
  data() {
    return {

    }
  },
  mounted() {
    

  },
  methods:{
   
  }


} 
</script>
<style lang="scss">
@import './assets/scss/reset.scss';
@import './assets/scss/config.scss';
@import './assets/scss/base.scss';
</style>
