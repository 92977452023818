export const dateYYMMDD = time => {
    if (!time) return;
    return time.slice(0, 10);
}

export function validateBottom(menuId, pageId, val) {
    let menuList = JSON.parse(localStorage.getItem('lferp_Menu'))
    // console.log(menuId,pageId,val)
    let flag = false
    if (menuId && pageId) {
        //  console.log('jin1')
        menuList.map(item => {
            if (item.id == menuId) {
                item.children.map(item2 => {
                    if (item2.id == pageId) {
                        item2.children.map(item3 => {
                            if (item3.label === val) {
                                //console.log('你愁啥')
                                flag = true
                            }
                        })
                    }
                })
            }
        })
    } else if (menuId && !pageId) {
        //  console.log('进2')
        menuList.map(item => {
            if (item.id == menuId) {
                item.children.map(item2 => {
                    if (item2.label === val) {
                        // console.log('你愁啥')
                        flag = true
                    }
                })
            }
        })
    }
    return flag
}

export function getTime(datetime) {
    let date = new Date(datetime);//时间戳为10位需*1000，13位的话不需要
    let Y = date.getFullYear() + "-";
    let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    let D = date.getDate();
    let H = date.getHours() + ":";
    let MIN = date.getMinutes();
    let S = date.getSeconds();
    if (S <= 9) {
        S = '0' + S
    }
    if (D <= 9) {
        D = '0' + D
    }
    if (MIN <= 9) {
        MIN = '0' + MIN
    }
    console.log()
    return Y + M + D + ' ' + H + MIN + ':' + S;
}
export function getTime1(datetime) {
    let date = new Date(datetime);//时间戳为10位需*1000，13位的话不需要
    let Y = date.getFullYear() + "-";
    let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    let D = date.getDate();
    let H = date.getHours() + ":";
    let MIN = date.getMinutes();
    let S = date.getSeconds();
    if (S <= 9) {
        S = '0' + S
    }
    if (D <= 9) {
        D = '0' + D
    }
    if (MIN <= 9) {
        MIN = '0' + MIN
    }
    console.log()
    return Y + M + D + ' ' + H + MIN;
}
export function getTime2(datetime) {
    let date = new Date(datetime);//时间戳为10位需*1000，13位的话不需要
    let Y = date.getFullYear() + "-";
    let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    let D = date.getDate();
    return Y + M + D
}

export function validateMobile(rule, value, callback) {
    let newValue = value.replace(/[^0-9]/gi, '')
    if (value !== newValue) {
        callback(new Error('请输入正确的手机号'))
    } else if (newValue.length !== 11) {
        callback(new Error('请输入正确的手机号'))
    } else {
        callback()
    }
}

//判断权限是否存在
export function isExitqx(id) {
    let menuList = JSON.parse(localStorage.getItem('lferp_Menu'))
    if (menuList.find(item => item.id === id)) {
        return true
    }
    else if (ischildrenFind(menuList, id)) {
        return true
    } else {
        for (let key of menuList) {
            if (key.children && ischildrenFind(key.children, id)) {
                return true
            }
        }
        return false
    }
}
function ischildrenFind(list, id) {
    for (let key of list) {
        if (key.children && key.children.find(item => item.id === id)) {
            return true
        }
    }
    return false
}
let flag = false

//递归查询是否存在id
function isExitId(id, list) {
    if (list.find(item => item.id === id)) {
        flag = true
    } else {
        for (let key of list) {
            if (key.children) {
                isExitId(id, key.children)
            }
        }
    }
}
export function hasId(id) {
    let menuList = JSON.parse(localStorage.getItem('lferp_Menu'))
    flag = false
    isExitId(id, menuList)
    return flag
}

export function getDateTime(datetime) {
    let date = new Date(datetime);//时间戳为10位需*1000，13位的话不需要
    let Y = date.getFullYear() + "-";
    let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    let D = date.getDate();
    let H = date.getHours();
    let MIN = date.getMinutes();
    let S = date.getSeconds();
    if (S <= 9) {
        S = '0' + S
    }
    if (D <= 9) {
        D = '0' + D
    }
    if (H <= 9) {
        H = '0' + H
    }
    H += ':'
    if (MIN <= 9) {
        MIN = '0' + MIN
    }
    console.log()
    return Y + M + D + ' ' + H + MIN + ':' + S;
}
//点击跳转客服
export function goAsk() {
    window.open('https://work.weixin.qq.com/kfid/kfc991d471b717e2879')
}