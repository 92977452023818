
import Vue from 'vue'
import VueRouter from 'vue-router'
import MyPage from './myPage'
import manage from './manage'
import product from './product'
import order from './order'
import information from './information'
import brand from './brand'
Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


const routes = [
  {
    path: '/xieyi',
    name: 'index',
    component: resolve => require(['/src/views/Login/xieyi'], resolve),
    meta: { title: '首页', keepAlive: true }
  },

  {
    path: '/',
    name: 'home',
    redirect: '/home',
    component: resolve => require(['/src/views/home'], resolve),
    meta: { title: '首页', keepAlive: false },
    children: [
      {
        path: '/home',
        name: 'index',
        component: resolve => require(['/src/views/index'], resolve),
        meta: { title: '首页', keepAlive: true }
      },
     
      ...order,
      ...MyPage,
      ...manage,
      ...product,
      ...information,
      ...brand,
    ]
  },

 

  




]

const router = new VueRouter({
  routes,
  // mode:'history'
})

export default router
