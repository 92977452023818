import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import store from './store'
import VueLazyLoad from 'vue-lazyload'
//const VueScrollTo = require('vue-scrollto')

import VueQuillEditor from 'vue-quill-editor'
import '@/assets/dan/dan.css'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor)
//Vue.use(VueScrollTo)

import scroll from 'vue-seamless-scroll'
Vue.use(scroll)

import { goAsk } from '@/utils/filters'
Vue.prototype.goAsk = goAsk
router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  window.scrollTo(0, 0);
  // window.pageYOffset = 0;
  // document.getElementsByClassName('home-container')[0].scrollTop = 0
  if (to.meta.title) {
    document.title = to.meta.title + '-洁易采'
  }
  // store.state.curGlobalUrl = to.path
  // console.log('store.state.curGlobalUrl:', store.state.curGlobalUrl)
  next();
})

Vue.config.productionTip = false
Vue.use(VueLazyLoad, {
  error: './assets/logo.png',
  loading: './asset/logo.png'
})

Vue.use(ElementUI);



new Vue({
  router,
  store,
  render: h => h(App),
  // mounted () {
  //   document.dispatchEvent(new Event('render-event'))
  //   }
}).$mount('#app')
