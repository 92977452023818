export default [
  {
    path: '/informationList',
    name: 'informationList',
    component: resolve => require(['/src/views/information/info'], resolve),
    meta: { title: '资讯列表', isToken: false, isNeedHeader: true }
  },
  {
    path: '/informationDetail',
    name: 'informationDetail',
    component: resolve => require(['/src/views/information/detail'], resolve),
    meta: { title: '资讯详情', isToken: false, isNeedHeader: true }
  },

] 