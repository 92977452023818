export default [
  {
    path: '/myPage',
    component: resolve => require(['/src/views/myPage/pageIndex'], resolve),
    meta: { title: '我的主页' },
    children: [
      {
        path: '/',
        name: 'myInfo',
        component: resolve => require(['/src/views/myPage/personInfo'], resolve),
        meta: { title: '我的主页', isToken: true, isNeedHeader: true }
      },
      {
        path: '/priceSheet',
        name: 'priceSheet',
        component: resolve => require(['/src/views/priceSheet/index.vue'], resolve),
        meta: { title: '报价单', isToken: true, isNeedHeader: true }
      },
      {
        path: '/afterSale',
        name: 'afterSale',
        component: resolve => require(['/src/views/payManage/afterSale/index.vue'], resolve),
        meta: { title: '售后列表', isToken: true, isNeedHeader: true }
      },
      {
        path: '/orderList',
        name: 'orderList',
        component: resolve => require(['/src/views/payManage/orderList/index.vue'], resolve),
        meta: { title: '订单列表', isToken: true, isNeedHeader: true }
      },
      {
        path: '/applyMember',
        name: 'applyMember',
        meta: { title: '申请会员', isToken: true, isNeedHeader: true },
        component: resolve => require(['/src/views/myPage/applyMember'], resolve),
      },
      {
        path: '/addressManage',
        name: 'addressManage',
        component: resolve => require(['/src/views/addressManage/indexTab.vue'], resolve),
        meta: { title: '地址管理', isToken: true, isNeedHeader: true }

      },
      {
        path: '/invoiceManage',
        name: 'invoiceManage',
        component: resolve => require(['/src/views/invoiceManage/indexTab.vue'], resolve),
        meta: { title: '发票管理', isToken: true, isNeedHeader: true }

      },
      {
        path: '/collectManage',
        name: 'collectManage',
        component: resolve => require(['/src/views/collectManage/indexTab.vue'], resolve),
        meta: { title: '收藏管理', isToken: true, isNeedHeader: true }

      },
      {
        path: '/coupon',
        component: resolve => require(['/src/views/myPage/couponIndex'], resolve),
        meta: { title: '我的优惠券', isToken: true, isNeedHeader: true }

      },
      {
        path: '/myMessage',
        component: resolve => require(['/src/views/myPage/myMessage/message'], resolve),
        meta: { title: '我的消息', isToken: true, isNeedHeader: true }

      },
      // {
      //   path: '/evaluate',
      //   name: 'evaluate',
      //   component: resolve => require(['/src/views/productList/evaluate'], resolve),
      //   meta: { title: '订单评价', isToken: true, isNeedHeader: true } 
      // },
    ]
  }, 
  {
    path: '/priceDetail',
    name: 'priceDetail',
    component: resolve => require(['/src/views/priceSheet/detail.vue'], resolve),
    meta: { title: '报价单明细', isToken: true, isNeedHeader: true }
  },
]

