export default [
  {
    path: '/brandDetail',
    name: 'brandDetail',
    component: resolve => require(['/src/views/brandManage/brandDetail'], resolve),
    meta: { title: '品牌详情', isToken: false, isNeedHeader: true }
  },
  {
    path: '/brandCenter',
    name: 'brandCenter',
    component: resolve => require(['/src/views/brandManage/brandCenter'], resolve),
    meta: { title: '品牌中心', isToken: false, isNeedHeader: true }
  },
]