export default [
    {
        path: '/problem',
        name: 'problem',
        component: resolve => require(['/src/views/problem/rewardCenter.vue'], resolve),
        meta: { title: '问题反馈', isToken: false, isNeedHeader: true }
    },
    {
        path: '/articlePage',
        name: 'articlePage',
        component: resolve => require(['/src/views/business/articlePage.vue'], resolve),
        meta: { title: '文章页面', isToken: false }
    },
    {
        path: '/proIndex',
        name: 'proIndex',
        component: resolve => require(['/src/views/proknowledge/proIndex.vue'], resolve),
        meta: { title: '产品知识', isToken: false }
    },
    {
        path: '/proDetail',
        name: 'proDetail',
        component: resolve => require(['/src/views/proknowledge/proDetail.vue'], resolve),
        meta: { title: '知识文章', isToken: false }
    },
    {
        path: '/helpCenter',
        name: 'helpCenter',
        component: resolve => require(['/src/views/helpCenter/helpCenter.vue'], resolve),
        meta: { title: '帮助中心', isToken: false, isNeedHeader: true }
    },
    {
        path: '/engineerSevice',
        name: 'engineerSevice',
        component: resolve => require(['/src/views/engineerSevice/index.vue'], resolve),
        meta: { title: '工程服务', isToken: false, isNeedHeader: true }
    },
    {
        path: '/business',
        name: 'business',
        component: resolve => require(['/src/views/business/adPosition.vue'], resolve),
        meta: { title: '商家入驻', isToken: false }
    },
    {
        path: '/sencePurchase',
        name: 'sencePurchase',
        component: resolve => require(['/src/views/sencePurchase/index.vue'], resolve),
        meta: { title: '场景采购', isToken: false }
    },
    {
        path: '/sencePurchaseDetail',
        name: 'sencePurchaseDetail',
        component: resolve => require(['/src/views/sencePurchase/detail.vue'], resolve),
        meta: { title: '场景采购详情', isToken: false }
    },

    {
      path: '/pointOrderDetail',
      name: 'pointOrderDetail',
      component: resolve => require(['/src/views/pointMall/orderDetail'], resolve),
      meta: { title: '积分订单详情', keepAlive: true,isNeedHeader: true }
    },
    {
      path: '/pointOrderList',
      name: 'pointOrderList',
      component: resolve => require(['/src/views/pointMall/orderList'], resolve),
      meta: { title: '订单列表', keepAlive: true }
    },
    {
      path: '/pointManageAddress',
      name: 'pointManageAddress',
      component: resolve => require(['/src/views/pointMall/manageAddress'], resolve),
      meta: { title: '管理收货地址', keepAlive: true,isNeedHeader: true }
    },
    {
      path: '/pointMallDetail',
      name: 'pointMallDetail',
      component: resolve => require(['/src/views/pointMall/detail'], resolve),
      meta: { title: '积分商品详情', keepAlive: true ,isNeedHeader: true}
    },
    {
      path: '/pointMall',
      name: 'pointMall',
      component: resolve => require(['/src/views/pointMall/index'], resolve),
      meta: { title: '积分商城', keepAlive: true }
    },
    {
      path: '/pointOrderSuccess',
      name: 'pointOrderSuccess',
      component: resolve => require(['/src/views/pointMall/success'], resolve),
      meta: { title: '兑换成功', keepAlive: true }
    },
    {
      path: '/confirmAddress',
      name: 'confirmAddress',
      component: resolve => require(['/src/views/pointMall/confirmAddress'], resolve),
      meta: { title: '确认收货地址', keepAlive: true ,isNeedHeader: true}
    },
    {
      path: '/addAddress',
      name: 'addAddress',
      component: resolve => require(['/src/views/pointMall/addAddress'], resolve),
      meta: { title: '新增收货地址', keepAlive: true }
    },
    {
        path:'/pointLayout',
        name: 'pointLayout',
        redirect: '/pointMall',
        component: resolve => require(['/src/views/pointMall/layout'], resolve),
        meta: { title: '积分商城', keepAlive: false, isNeedHeader: true  },
        // children: [
          
    
        // ]
      },
     
]  