import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    activeIndex: 0,
    name: '',
    level: -1,
    token: '',
    curLoaction: {
      prov: {
        id: '320000',
        name: '江苏'
      },
      city: {
        id: '320500',
        name: '苏州'
      }
    },
    userInfo: {//当前登录用户信息
      headPortrait: ''
    },
    isAreaShow: false,
    //首页选择的某一项
    homeSelect: ''
  },
  getters: {
    getCurLoaction: state => state.curLoaction,
    getUserHeaderImg: state => state.userInfo.headPortrait,
    getUserInfo: state => state.userInfo,
    areaShow: state => state.isAreaShow,
    getHomeSelect: state => state.homeSelect
  },
  mutations: {
    //设置顶部地区显示
    setAreaShow(state, show) {
      state.isAreaShow = show
      console.log(state.isAreaShow);
    },
    //更新省份信息
    updateProvinceInfo(state, obj) {
      state.curLoaction.prov.name = obj.name
      state.curLoaction.prov.id = obj.id
    },
    //更新城市信息
    updateCityInfo(state, obj) {
      state.curLoaction.city.name = obj.name
      state.curLoaction.city.id = obj.id
    },
    //更换用户头像
    updateUserAvator(state, img) {
      state.userInfo.headPortrait = img
    },
    //更新用户信息
    updateUserInfo(state, userInfo) {
      state.userInfo = userInfo
    },
    //改变首页选择
    changeHomeSelect(state, homeSelect) {
      state.homeSelect = homeSelect
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState()]
  // plugins: []


})
