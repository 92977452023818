export default [
  {
    path: '/productClassify',
    name: 'productClassify',
    component: resolve => require(['/src/views/productList/productClassify'], resolve),
    meta: { title: '产品分类', isToken: false }
  },
  {
    path: '/productList',
    name: 'productList',
    component: resolve => require(['/src/views/productList/productList'], resolve),
    meta: { title: '产品列表', isToken: false }
  },
  {
    path: '/productCompare',
    name: 'productCompare',
    component: resolve => require(['/src/views/productList/productCompare'], resolve),
    meta: { title: '产品对比', isToken: false }
  },
  {
    path: '/productDetail',
    name: 'productDetail',
    component: resolve => require(['/src/views/productList/productDetail/productDetail'], resolve),
    meta: { title: '标品产品详情', isToken: false, isNeedHeader: true }
  },
  {
    path: '/nostandardproductDetail',
    name: 'nostandardproductDetail',
    component: resolve => require(['/src/views/productList/productDetail/noStandardProductDetail'], resolve),
    meta: { title: '非标品产品详情', isToken: false }
  },

] 